<template>
  <div>
    <v-banner
        v-model="showBanner"
        single-line
        transition="slide-y-transition"
        shaped
        class="font-italic"
    >
      Wählen Sie eine Fahraufgabe.
    </v-banner>
    <v-row>
      <v-col
          v-for="(drivingTask, i) in getselecteddrivingtaskcatalog"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
      >
        <CardGrid :title=drivingTask.name
                  :img_src=drivingTask.img_src
                  :propsdata=drivingTask
                  :dtid=i+1
        ></CardGrid>

      </v-col>
    </v-row>
    <br>
    <v-divider></v-divider>
    <br>
    <v-container class="pa-0">
      <v-row>
        <v-col
            cols="6"
            md="4"
        >
          <VideoDialog
              title="Die Praktische Fahrerlaubnisprüfung in Deutschland"
              description="Erklärfilm - Allgemeine Informationen zu den Inhalten und zur Durchführung der Prüfung"
              video_src="argetp21FilmPFEP2021_1080p.mp4"
              video_poster="/video_poster/pfepfilm.jpg"
          ></VideoDialog>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="8"
        >
          <div class="pa-3">Erklärfilm „Die Praktische Fahrerlaubnisprüfung in Deutschland“ - Allgemeine Informationen
            zu
            den Inhalten und zur Durchführung der Prüfung
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import CardGrid from "@/components/items/CardGrid.vue";
import VideoDialog from "@/components/items/VideoDialog";


export default Vue.extend({
  name: "DrivingTaskGrid",

  components: {
    VideoDialog,
    CardGrid,
  },
  props:{
    drivingtasks:{
      type: Array,
    }
  },

  data: () => ({
    showBanner: false,
  }),
  computed:{
    getselecteddrivingtaskcatalog(){
      return this.$store.state.selecteddrivingtaskcatalog;
    }
  },
  activated() {
    this.showBanner = true;
  },
  deactivated() {
    this.showBanner = false;
  }

});

</script>

<style lang="scss">
</style>