
import Vue from "vue";

export default Vue.extend({
  name: "CardGrid",
  props: {
    title: {
      type: String,
    },
    img_src: {},
    propsdata: {
      type: Object,
    },
    dtid: {
      type: Number,
    }
  },

  data: () => ({
    //
  }),
});
