
import Vue from "vue";
import VideoPlayer from "@/components/items/VideoPlayer.vue";

/**
 * @displayName VideoDialog
 * @description VideoDialog is a modal which show a video with title and description
 */
export default Vue.extend({
  name: "VideoDialog",
  components: {
    VideoPlayer
  },
  data: () => ({
    dialog: false,
  }),
  props: {
    title: {
      type: String,
    },
    video_src: {},
    video_poster: {},
    description: {}
  },
  methods: {
    onClickOutsideOfDialog() {
      if(this.dialog){
        const collection = Array.from(document.getElementsByTagName("video"));
        collection.forEach(function (videop){
          videop.pause();
        })
      }

    }
  }
})

